#first-row {
  padding-top: 25vh !important;
}

.content {
  width: 100vw !important;
}

#back-btn-col {
  padding: 0 !important;
}

#back-btn {
  width: 100% !important;
  border-radius: 0 !important;
  background-color: #f3f0eb !important;
}

#back-btn:hover {
  background-color: #eceae5 !important;
}

#back-btn:focus {
  color: #b0b0b0 !important;
}

.section-title {
  background-color: #134c5c !important;
  color: white !important;
  font-weight: bold !important;
}

.creme-bckgrnd {
  background-color: #f3f0eb !important;
}

.div-style {
  width: 60% !important;
}

.tbl-a {
  background-color: #257182 !important;
  border-radius: 0 !important;
}

.tbl-hd {
  color: white !important;
  font-weight: bold !important;
  font-size: medium !important;
}

.tbl-rw-odd {
  background-color: #f3f0eb !important;
}

.tbl-rw-even {
  background-color: white !important;
}

.notas-title {
  background-color: #f68b29 !important;
  color: white !important;
  padding: 10px !important;
}

.dimensionesImg {
  width: auto;
  height: 25rem;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}
