@media only screen and (min-width: 768px) {
  .rectangulo {
    margin-top: 15%;
    /* Rectangle 1 */
    width: 25%;
    height: 15%;
    left: 51%;
    /* Blanco */
    background: #ffffff;
    /* Naranja */
    border: 8px solid #f68b29;
    margin-left: -7%;
  }

  .rectanguloRP {
    margin-top: 10%;
    /* Rectangle 1 */
    width: 25%;
    height: 15%;
    left: 51%;
    /* Blanco */
    background: #ffffff;
    /* Naranja */
    border: 8px solid #f68b29;
    margin-left: -7%;
  }

  .barraUno {
    /* Barra1 */
    position: absolute;
    width: 4%;
    height: 100%;
    left: 40%;
    top: 0px;

    /* Naranja */
    background: #f68b29;
  }

  .barraDos {
    /* Barra2 */
    position: absolute;
    width: 4%;
    height: 100%;
    left: 46%;
    top: 0px;
    z-index: -2;

    /* Naranja */
    background: #f68b29;
  }

  .barraTransversal {
    /* Barra Transversal */
    position: absolute;
    width: 100%;
    height: 15%;
    top: 50%;
    z-index: -1;
    /* Azul Petróleo */
    background: #134c5c;
  }

  .botonPSW {
    background-color: #ffff !important;
    border-color: #ffff !important;
    border-width: 0 !important;
    border-radius: 0px !important;
    font-size: 1em !important;
    font-weight: bold !important;
    color: #134c5c !important;
    text-decoration: underline;
  }

  .botonPSW:hover {
    color: rgb(30, 33, 211) !important;
  }

  .botonPSW:focus {
    color: rgb(30, 33, 211) !important;
  }
}

@media only screen and (min-width: 600px) and (max-height: 550px) {
  .rectanguloMD {
    margin-top: 0%;
    /* Rectangle 1 */
    left: 51%;
    /* Blanco */
    background: #ffffff;
    /* Naranja */
    border: 8px solid #f68b29;
  }

  .botonPSWMD {
    background-color: #ffff !important;
    border-color: #ffff !important;
    border-width: 0 !important;
    border-radius: 0px !important;
    font-weight: bold !important;
    color: #134c5c !important;
    text-decoration: underline;
    font-size: 0.8em !important;
  }

  .botonPSWMD:hover {
    color: rgb(30, 33, 211) !important;
  }

  .botonPSWMD:focus {
    color: rgb(30, 33, 211) !important;
  }

  .botonLoginMD {
    background: #134c5c !important;
    color: white;
    width: 70%;
    border-radius: 1rem;
    font-size: 12px !important;
  }

  .botonLoginMD:hover {
    color: white;
    border-color: black;
  }

  .botonPSWMD {
    background-color: #ffff !important;
    border-color: #ffff !important;
    border-width: 0 !important;
    border-radius: 0px !important;
    font-weight: bold !important;
    color: #134c5c !important;
    text-decoration: underline;
    font-size: 0.8em !important;
  }

  .botonPSWMD:hover {
    color: rgb(30, 33, 211) !important;
  }

  .botonPSWMD:focus {
    color: rgb(30, 33, 211) !important;
  }
}

@media only screen and (max-width: 600px) {
  /* For tablets: */
  .rectanguloSM {
    margin-top: 0%;
    /* Rectangle 1 */
    left: 51%;
    /* Blanco */
    background: #ffffff;
    /* Naranja */
    border: 8px solid #f68b29;
  }

  .botonPSWSM {
    background-color: #ffff !important;
    border-color: #ffff !important;
    border-width: 0 !important;
    border-radius: 0px !important;
    font-weight: bold !important;
    color: #134c5c !important;
    text-decoration: underline;
    font-size: 0.8em !important;
  }

  .botonPSWSM:hover {
    color: rgb(30, 33, 211) !important;
  }

  .botonPSWSM:focus {
    color: rgb(30, 33, 211) !important;
  }
}

.rectanguloMD {
  /* Rectangle 1 */
  left: 51%;
  /* Blanco */
  background: #ffffff;
  /* Naranja */
  border: 8px solid #f68b29;
}

.botonLogin {
  background: #134c5c !important;
  color: white;
  width: 100%;
  border-radius: 1rem;
}

.botonLogin:hover {
  color: white;
  border-color: black;
}

.titleDos {
  transform: scale(1, 1.4);
}

.marginBottom {
  margin-bottom: 5rem;
}

.botonPSW {
  background-color: #ffff !important;
  border-color: #ffff !important;
  border-width: 0 !important;
  border-radius: 0px !important;
  font-weight: bold !important;
  color: #134c5c !important;
  text-decoration: underline;
  font-size: 1em;
}

.botonPSW:hover {
  color: rgb(30, 33, 211) !important;
}

.botonPSW:focus {
  color: rgb(30, 33, 211) !important;
}

.textoPSW {
  font-size: 0.9em !important;
  font-weight: bold !important;
}

.estiloBotonEye {
  float: right;
  margin-top: -44px;
  position: relative;
  z-index: 2;
}

.estiloEye {
  background-color: transparent !important;
  margin-left: -2rem;
}
