#circle-green {
  color: #00a651 !important;
  z-index: 3;
}

#circle-yellow {
  color: #ffca56 !important;
  position: absolute;
  z-index: 2;
}

#circle-red {
  color: #cc1111 !important;
  position: absolute;
  z-index: 1;
}

#circle-bg {
  color: #bcbec0 !important;
  position: absolute;
}

#percent {
  font-size: x-large !important;
}
