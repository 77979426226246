#title {
  font-family: "Orbeit";
  color: #f68b29;
  transform: scale(1, 1.4);
}

#btn-grp {
  width: 100% !important;
}

#selected {
  color: white !important;
  background-color: #f68b29 !important;
}

.btn {
  border-width: 0 !important;
  border-color: white !important;
  border-radius: 0px !important;
  width: 100% !important;
  font-size: large !important;
  font-weight: bold !important;
  background-color: #f3f0eb !important;
  padding: 12px 0 12px 0 !important;
  color: #134c5c !important;
}

.btn:focus {
  box-shadow: none !important;
}

.new-btn {
  border-width: 0 !important;
  border-radius: 0px !important;
  width: 100% !important;
  font-size: medium !important;
  font-weight: bold !important;
  background-color: #134c5c !important;
  padding: 12px 0 12px 0 !important;
  color: white !important;
}

.log-btn {
  background-color: #257182 !important;
}

.new-btn:disabled {
  background-color: #808285 !important;
}

.fixed {
  width: 100% !important;
  position: fixed !important;
  z-index: 4;
  background-color: white !important;
}

.bg-w {
  background-color: white !important;
}
