#title {
  font-family: "Orbeit";
  color: #f68b29;
  transform: scale(1, 1.4);
}

.fixed {
  width: 100% !important;
  position: fixed !important;
  z-index: 1;
  background-color: white;
}
