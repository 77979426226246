.txt-data {
  font-weight: normal !important;
  font-size: medium !important;
  padding-left: 7px !important;
}

.txt-link {
  font-weight: normal !important;
  font-size: medium !important;
  padding-left: 7px !important;
  color: #257182 !important;
}

.cir_gray {
  color: #808285 !important;
  font-size: xx-large !important;
}

.cir_green {
  color: #00a651 !important;
  font-size: xx-large !important;
}

.cir_yellow {
  color: #ffca56 !important;
  font-size: xx-large !important;
}

.cir_red {
  color: #cc1111 !important;
  font-size: xx-large !important;
}
