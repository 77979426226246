#log-header {
  background-color: #257182 !important;
}

.log-header_cells {
  background-color: #257182 !important;
}

.log-header_labels {
  color: white !important;
  font-weight: bold !important;
  font-size: medium !important;
}

#log-tbl-cont {
  height: 64vh !important;
  overflow-y: auto !important;
}

.cir-gray {
  color: #808285 !important;
}

.cir-green {
  color: #00a651 !important;
}

.cir-yellow {
  color: #ffca56 !important;
}

.cir-red {
  color: #cc1111 !important;
}
