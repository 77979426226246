#header {
  background-color: #f68b29 !important;
}

.header_cells {
  background-color: #f68b29 !important;
}

.header_labels {
  color: white !important;
  font-weight: bold !important;
  font-size: medium !important;
}

#tbl-cont {
  height: 70vh !important;
  overflow-y: auto !important;
}

.cir-gray {
  color: #808285 !important;
}

.cir-blue {
  color: #134c5c !important;
}

.cir-green {
  color: #00a651 !important;
}

.cir-yellow {
  color: #ffca56 !important;
}

.cir-red {
  color: #cc1111 !important;
}

.cir-brown {
  color: #600f40 !important;
}

.MuiTableSortLabel-icon {
  color: white !important;
}

.odd-row {
  background-color: #f3f0eb !important;
}

.row-style {
  border: 0px !important;
}

.disabled-row {
  background-color: #80828570 !important;
}

.icn-btn {
  color: #134c5c !important;
  margin: 0 3px !important;
}

.icn-btn:disabled {
  color: #808285 !important;
}

.icn-btn-green {
  color: #00a651 !important;
  margin: 0 3px !important;
}

.activate-btn {
  color: white !important;
  background-color: #00a651 !important;
  border-radius: 0 !important;
}

.cancel-btn {
  color: white !important;
  background-color: #808285 !important;
  border-radius: 0 !important;
}

.delete-btn {
  color: white !important;
  background-color: #cc1111 !important;
  border-radius: 0 !important;
}

.MuiDialog-paper {
  border-radius: 0px !important;
  background-color: #f3f0eb !important;
}

#alert-dialog-title {
  background-color: #134c5c !important;
  color: white !important;
}

.alert-dialog-content {
  background-color: #f3f0eb !important;
  padding-top: 17px !important;
  text-align: center !important;
}

.alert-dialog-actions {
  background-color: #f3f0eb !important;
  align-self: center !important;
  padding-bottom: 15px !important;
}

.MuiTooltip-tooltip {
  background-color: #ebeaec !important;
  color: #000000 !important;
  box-shadow: 0px 2px 5px #00000056 !important;
  border-radius: 0 !important;
}

.Toastify__toast--success {
  border-radius: 0 !important;
}
