.first-row {
  padding-top: 25vh !important;
}

.content {
  width: 100% !important;
  margin: 0 !important;
  padding-right: 0;
}

.creme-bckgrnd {
  background-color: #f3f0eb !important;
}

.botonCapturaEnviar {
  background: #f68b29 ;
  color: white;
  border: #f68b29;
  width: 13rem;
  height: 3rem;
}
.botonTerminar {
    background: #F68B29;
    color: white;
    border: #F68B29;
    width: 13rem;
    height: 3rem;
}
.botonTerminard {
    background: #8b8b8b;
    color: rgb(255, 255, 255);
    border: #F68B29;
    width: 13rem;
    height: 3rem;
}
.botonCapturaEnviar:hover {
  background: #e36526;
}

.marginBotonEnvir {
  margin-top: auto;
  margin-bottom: auto;
}

.section-title {
  background-color: #134c5c !important;
  color: white !important;
  font-weight: bold !important;
}

.titulos {
  padding: 10px;
  width: 100%;
  height: 3rem;
  left: 0px;
  top: 0px;
  background: #134c5c;
  font-weight: bold !important;
}

.textoTitulos {
  /* Información Básica */
  text-align: left;

  color: #ffffff;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
}

.scroll {
  overflow-x: hidden !important;
}

.formatoBotonArchivos {
  background-color: #134c5c !important;
}

.centrarBotones {
  display: flex;
  justify-content: center;
}

.estiloInputs {
  background-color: #f3f0eb !important;
  border: 1px solid #f3f0eb !important;
}

.estiloInputsText {
  z-index: 1 !important;
  background-color: #f3f0eb !important;
  border-top-color: #f3f0eb !important;
  border-left-color: #f3f0eb !important;
  border-right-color: #f3f0eb !important;
  border-bottom-color: black !important;
}

.estiloInputsText:disabled {
  background-color: #8082854d !important;
}

.estiloObservaciones {
  background-color: #f3f0eb !important;
  border-top-color: black !important;
  border-left-color: black !important;
  border-right-color: black !important;
  border-bottom-color: black !important;
}

.estiloObservaciones:disabled {
  background-color: #8082854d !important;
}

.row-login-form {
  padding-right: 0;
}

.estiloBotonCancelar {
  background-color: #f3f0eb !important;
  border: 4px solid #f68b29 !important;
}

.estiloBotonCancelar:hover {
  background-color: #f68b29 !important;
  color: white !important;
}

.estiloBotonAceptar {
  background-color: #f68b29;
  border: 4px solid #f68b29 !important;
  color: white !important;
}

.estiloBotonAceptar:hover {
  background-color: #9b2f74 !important;
  border: 4px solid #600f40 !important;
}

.estiloBotonGuardar {
  background-color: #e36526 !important;
  border: 4px solid #e36526 !important;
  color: white !important;
}

.estiloBotonGuardar:hover {
  background-color: #257182 !important;
  border: 4px solid #257182 !important;
}

.fontCirculo {
  font-size: 40px !important;
  margin-left: 0.5rem;
}

.fontCirculoIdentificacion {
  font-size: 40px !important;
  margin-left: 0.5rem;
  margin-top: 1rem;
}

.formatoBotonMov {
  background-color: #134c5c !important;
  width: 14rem;
}

.borderTopMovimiento {
  border-top-color: black !important;
  margin-top: 3rem;
  border-top: 2px;
  border-top-style: dashed;
}

.formatoBotonMovDisabled {
  color: white !important;
  opacity: 1;
  pointer-events: none;
  cursor: default !important;
  box-shadow: none !important;
  background-color: #939598 !important;
}
