.card {
  border-radius: 0 !important;
  border: 0;
  margin: 25px 10px 5px 10px !important;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.35) !important;
}

.card-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.img-card {
  height: 130px !important;
  margin: 7% 20% 7% 20% !important;
}

.odd-row {
  background-color: #e6e7e8 !important;
}

.data-label {
  padding-left: 10% !important;
  font-size: large !important;
  font-weight: bold !important;
}

.more-btn {
  font-size: large !important;
  font-weight: bold !important;
  width: 60% !important;
  margin: 0 20% 10px 20% !important;
  padding: 2% 0 2% 0 !important;
  border-radius: 0 !important;
  color: white !important;
  background-color: #134c5c !important;
}
