.cherry {
  background: #600f40 !important;
}

#nav-dropdown {
  color: white;
  background: #600f40 !important;
  background-color: #600f40 !important;
}

div.dropdown-menu.show {
  background-color: #600f40;
}

#logout {
  color: white;
  background: #600f40 !important;
}

.icon {
  font-size: xx-large !important;
}
