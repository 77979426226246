.fondo {
  position: absolute;
  background: #134c5c;
  height: 100%;
  width: 100%;
}

.textoPage {
  font-family: "Orbeit" !important;
  color: #f3f0eb !important;
}

.texto404 {
  font-family: "Orbeit" !important;
  color: #f3f0eb !important;
  text-align: end !important;
  margin-right: -28rem !important;
  margin-top: 9rem !important;
}

.imagen {
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-white {
  filter: invert(97%) sepia(3%) saturate(441%) hue-rotate(348deg)
    brightness(102%) contrast(90%);
}
