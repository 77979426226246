.first-row {
  padding-top: 25vh !important;
}

.content {
  width: 100% !important;
  margin: 0 !important;
  padding-right: 0;
}

.creme-bckgrnd {
  background-color: #f3f0eb !important;
}

.botonCapturaEnviar {
  background: #f68b29;
  color: white;
  border: #f68b29;
  width: 12rem;
  height: 3rem;
}

.botonCapturaEnviar:hover {
  background: #e36526
}

.botonCapturaEnviarDesactivado {
  background: #c7c5c1 !important;
  color: #6d6b67;
  border: #c7c5c1;
  width: 12rem;
  height: 3rem;
  pointer-events: none;
}

.marginBotonEnvir {
  margin-top: auto;
  margin-bottom: auto;
}

.section-title {
  background-color: #134c5c !important;
  color: white !important;
  font-weight: bold !important;
}

.titulos {
  padding: 10px;
  width: 100%;
  height: 3rem;
  left: 0px;
  top: 0px;
  background: #134c5c;
  font-weight: bold !important;
}

.titulosMov {
  padding: 10px;
  width: 100%;
  height: 3rem;
  left: 0px;
  top: 0px;
  background: #939598;
  font-weight: bold !important;
}

.textoTituloMov {
  /* Información Básica */
  text-align: left;

  color: #ffffff;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 16px;
}

.scroll {
  overflow-x: hidden !important;
}

.formatoBotonArchivos {
  background-color: #134c5c !important;
}

.centrarBotones {
  display: flex;
  justify-content: center;
}

.estiloInputs {
  background-color: #f3f0eb !important;
  border: 1px solid #f3f0eb !important;
}

.estiloInputsText {
  z-index: 1 !important;
  background-color: #f3f0eb !important;
  border-top-color: #f3f0eb !important;
  border-left-color: #f3f0eb !important;
  border-right-color: #f3f0eb !important;
  border-bottom-color: black !important;
}

.estiloInputsMov {
  background-color: #f3f0eb !important;
  border: 1px solid #f3f0eb !important;
  color: #939598;
}

.estiloInputsTextMov {
  z-index: 1 !important;
  background-color: #f3f0eb !important;
  border-top-color: #f3f0eb !important;
  border-left-color: #f3f0eb !important;
  border-right-color: #f3f0eb !important;
  border-bottom-color: #939598 !important;
  color: #939598;
}

.estiloObservaciones {
  background-color: #f3f0eb !important;
  border-top-color: black !important;
  border-left-color: black !important;
  border-right-color: black !important;
  border-bottom-color: black !important;
}

.estiloObservacionesMov {
  background-color: #f3f0eb !important;
  border-top-color: #939598 !important;
  border-left-color: #939598 !important;
  border-right-color: #939598 !important;
  border-bottom-color: #939598 !important;
  color: #939598;
}

.row-login-form {
  padding-right: 0;
}

.estiloBotonCancelar {
  background-color: #f3f0eb !important;
  border: 4px solid #f68b29 !important;
}

.estiloBotonCancelar:hover {
  background-color: #f68b29 !important;
  color: white !important;
}

.estiloBotonAceptar {
  background-color: #f68b29 !important;
  border: 4px solid #f68b29 !important;
  color: white !important;
}

.estiloBotonAceptar:hover {
  background-color: #9b2f74 !important;
  border: 4px solid #600f40 !important;
}

.estiloBotonGuardar {
  background-color: #e36526 !important;
  border: 4px solid #e36526 !important;
  color: white !important;
}

.estiloBotonGuardar:hover {
  background-color: #257182 !important;
  border: 4px solid #257182 !important;
}

.estiloBotonGuardarBloqueado {
  background-color: #c7c5c1 !important;
  border: 4px solid #c7c5c1 !important;
  color: #6d6b67 !important;
  pointer-events: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.estiloIconArchivo {
  color: #134c5c !important;
  background-color: #f3f0eb !important;
  font-size: 2.8rem !important;
}

.estiloIconoArchivoDos {
  font-size: 2.8rem !important;
  margin-top: -0.7rem !important;
}

.estiloIconArchivo:hover {
  color: #106279 !important;
}

.claseTextoActaBoton {
  width: 14rem;
}

.claseTextoActaSinBoton {
  width: 11rem;
}

.claseTextoFotoInfBoton {
  width: 14rem;
  margin-left: 3rem;
}

.claseTextoFotoInfSinBoton {
  width: 11rem;
  margin-left: 3rem;
}

.claseTextoFotoFirBoton {
  width: 14rem;
  margin-left: 4rem;
}

.claseTextoFotoFirSinBoton {
  width: 11rem;
  margin-left: 4rem;
}
