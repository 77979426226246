.subnavbar {
  min-height: 7.5rem !important;
  max-height: 7.5rem !important;
}

.textBotones {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
}

.textBotones:hover {
  color: white !important;
  background-color: #f68b29 !important;
}

.subnav-img {
  width: 100% !important;
  max-height: 7.5rem !important;
}
